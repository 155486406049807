import classes from './EventForm.module.scss';

import React, { useState } from 'react';
import DynamicFormBuilder from '@lobox/uikit/Form/DynamicFormBuilder';
import Flex from '@lobox/uikit/Flex';
import Form from '@lobox/uikit/Form';
import Typography from '@lobox/uikit/Typography';
import DropdownSelect from '@lobox/uikit/AutoComplete/DropdownSelect';
import Button from '@lobox/uikit/Button';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import useToast from '@lobox/uikit/Toast/useToast';
import IconButton from '@lobox/uikit/Button/IconButton';
import CheckBox from '@lobox/uikit/CheckBox';
import SubmitButton from '@lobox/uikit/Form/SubmitButton';
import cnj from '@lobox/uikit/utils/cnj';
import {
  QueryKeys,
  schedulesApi,
  schedulesDb,
  useReactMutation,
  useTranslation,
  useUpdateQueryData,
} from '@lobox/utils';
import { useFormikContext } from 'formik';
import useGetCurrentTimeZone from '@shared/hooks/api-hook/useGetCurrentTimeZone';
import type { UnArray } from '@shared/types/schedules';
import type { Timesheet } from './types';
import type { FC } from 'react';

interface Props {
  data: Timesheet;
  removeNewTimesheet?: () => void;
  isEditActive?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  classNames?: {
    buttonsContainer?: string;
  };
}

type FormValues = {
  availabilityName: string;
  meetingDuration: {
    value: string;
    label: string;
  };
  [weekDays: string]: any;
};

const EventForm: FC<Props> = ({
  data,
  removeNewTimesheet,
  isEditActive,
  onSuccess,
  onCancel,
  classNames,
}) => {
  const isNew = !data?.id;
  const { t } = useTranslation();
  const { openConfirmDialog } = useOpenConfirm();
  const toast = useToast();
  const [isEditMode, setIsEditMode] = useState(isNew || !!isEditActive);
  const { data: currentTimeZone } = useGetCurrentTimeZone();

  const { mutate: removeAvailabilityMutation } = useReactMutation({
    apiFunc: schedulesApi.deleteTimesheet,
  });
  const { refetch } = useUpdateQueryData(QueryKeys.schedulesPreferences);
  const { refetch: refetchUserData } = useUpdateQueryData([
    QueryKeys.schedulesPreferences,
    QueryKeys.authUser,
  ]);

  const removeAvailability = () => {
    openConfirmDialog({
      title: t('remove_availability'),
      message: t('are_you_sure_you_want_to_remove_the_availability'),
      confirmButtonText: t('remove'),
      cancelButtonText: t('cancel'),
      confirmCallback: () => {
        removeAvailabilityMutation(
          {
            id: data?.id,
          },
          {
            onSuccess: () => {
              toast({
                type: 'success',
                icon: 'check-circle',
                title: t('availability_removed'),
                message: t('availability_removed_successfully'),
              });
              refetch();
              refetchUserData();
            },
          }
        );
      },
    });
  };

  const handleCancel = (resetForm: Function) => () => {
    resetForm();
    setIsEditMode(false);
    if (onCancel) onCancel();
  };
  const transform = (values: typeof initialValues) => {
    const dailyHours: Timesheet['dailyHours'] = [];
    Object.keys(values)?.forEach((key) => {
      if (!schedulesDb.weekDays.map((day) => day.value).includes(key)) return;
      const dayArray = values[key]?.map((item: any) => ({ ...item, day: key }));
      dailyHours.push(...dayArray);
    });

    const newValues: Timesheet = {
      ...data,
      title: values?.availabilityName,
      duration: values?.meetingDuration?.value,
      dailyHours,
      timezoneCode: currentTimeZone?.code,
      timezoneId: currentTimeZone?.id,
      timezoneLabel: currentTimeZone?.label,
      timezoneOffset: currentTimeZone?.offset,
    };
    return newValues;
  };

  const handleSuccess = () => {
    if (isNew) {
      toast({
        type: 'success',
        icon: 'check-circle',
        title: t('availability_created'),
        message: t('availability_created_successfully'),
      });
      if (removeNewTimesheet) setTimeout(removeNewTimesheet, 0);
    } else {
      toast({
        type: 'success',
        icon: 'check-circle',
        title: t('availability_changed'),
        message: t('availability_changed_successfully'),
      });
    }
    refetch();
    if (onSuccess) onSuccess?.();
  };

  const handleSubmit = (values: any) => {
    let res;
    if (isNew) {
      res = schedulesApi.postTimeSheet(values);
    } else {
      res = schedulesApi.putTimeSheet(values);
    }
    setIsEditMode(false);
    return res;
  };

  const initialValues: FormValues = {
    ...schedulesDb.weekDays.reduce((acc, curr) => {
      const dayDataArray = data?.dailyHours?.filter(
        (item) => item?.day === curr.value
      );
      if (!dayDataArray?.length) return acc;
      return {
        ...acc,
        [curr.value]: dayDataArray?.map((dayData) => ({
          ...dayData,
          start: dayData?.start?.slice(0, 5),
          end: dayData?.end.slice(0, 5),
        })),
      };
    }, {}),
    availabilityName: data?.title,
    meetingDuration: {
      value: data?.duration,
      label: schedulesDb.meetingDuration.find(
        (item) => item?.value === data?.duration
      )?.label,
    },
  };

  const name = {
    name: 'availabilityName',
    cp: 'input',
    label: t('availability_name'),
    wrapStyle: classes.nameInput,
  };

  const duration = {
    name: 'meetingDuration',
    cp: 'dropdownSelect',
    label: t('meeting_duration'),
    wrapStyle: classes.durationDropdown,
    options: schedulesDb.meetingDuration.filter(
      (item) => item?.value !== 'FLEXIBLE'
    ),
  };

  const _groups = schedulesDb.weekDays.map((item) => ({
    name: item?.value,
    cp: (props: any) => {
      const { setFieldValue } = useFormikContext();
      const valuesForASingleDay = props?.value || [];
      const isUnavailable = !valuesForASingleDay?.length;
      const isLastItem = props?.name === schedulesDb.weekDays?.at(-1)?.value;
      const isMaxLimitReached = valuesForASingleDay?.length >= 10;
      return (
        <Flex>
          <Flex className={classes.eventDayTopContainer}>
            {isEditMode ? (
              <Flex className={classes.titleWrapperInEdit}>
                <CheckBox
                  value={!isUnavailable}
                  label={item?.label?.slice(0, 3)}
                  onChange={(value: boolean) => {
                    const _data = !value
                      ? []
                      : [{ start: '09:00', end: '17:00' }];
                    setFieldValue(props?.name, _data);
                  }}
                  classNames={{
                    checkbox: classes.checkbox,
                    textContainer: classes.checkboxText,
                  }}
                />
                <IconButton
                  size="md18"
                  name="plus"
                  colorSchema="transparent"
                  onClick={() => {
                    if (isMaxLimitReached) return;
                    setFieldValue(props?.name, [
                      ...valuesForASingleDay,
                      { start: '09:00', end: '17:00' },
                    ]);
                  }}
                  type="far"
                />
              </Flex>
            ) : (
              <Typography
                size={15}
                color="smoke_coal"
                height={32}
                className={classes.dayTitle}
              >
                {item?.label?.slice(0, 3)}
              </Typography>
            )}
          </Flex>
          <Flex
            className={cnj(
              classes.eventDayBottomContainer,
              isLastItem && classes.noBorderBottom
            )}
          >
            {isUnavailable ? (
              <Typography size={15} color="secondaryDisabledText">
                {t('unavailable')}
              </Typography>
            ) : (
              <Flex>
                {valuesForASingleDay?.map((_, index: number) => (
                  <Flex
                    className={cnj(
                      classes.dropDownsContainer,
                      isEditMode && classes.dropDownsContainerEditable,
                      valuesForASingleDay?.length > 1 &&
                        index !== valuesForASingleDay?.length - 1 &&
                        classes.paddingBottomItem
                    )}
                  >
                    <DropdownSelect
                      rightIconClassName={classes.noBackground}
                      options={schedulesDb.timeOptions}
                      label={t('from')}
                      doNotUseTranslation
                      rightIconProps={{ name: 'clock' }}
                      // value={props?.value?.start}
                      displayName={
                        schedulesDb.timeOptions.find(
                          (item) =>
                            item?.value === valuesForASingleDay?.[index]?.start
                        )?.label
                      }
                      disabledReadOnly={!isEditMode}
                      onChange={(
                        value: UnArray<typeof schedulesDb.meetingDuration>
                      ) => {
                        const changedItemIndex = valuesForASingleDay?.findIndex(
                          (_, ind: number) => ind === index
                        );
                        const _values = [...valuesForASingleDay];
                        _values[changedItemIndex] = {
                          ...valuesForASingleDay[changedItemIndex],
                          start: value?.value,
                        };
                        setFieldValue(props?.name, _values);
                      }}
                    />
                    <DropdownSelect
                      rightIconClassName={classes.noBackground}
                      options={schedulesDb.timeOptionsEnd}
                      label={t('to')}
                      doNotUseTranslation
                      rightIconProps={{ name: 'clock' }}
                      // value={props?.value?.end}
                      displayName={
                        schedulesDb.timeOptionsEnd.find(
                          (item) =>
                            item?.value === valuesForASingleDay?.[index]?.end
                        )?.label
                      }
                      disabledReadOnly={!isEditMode}
                      onChange={(
                        value: UnArray<typeof schedulesDb.meetingDuration>
                      ) => {
                        const changedItemIndex = valuesForASingleDay?.findIndex(
                          (_, ind: number) => ind === index
                        );
                        const _values = [...valuesForASingleDay];
                        _values[changedItemIndex] = {
                          ...valuesForASingleDay[changedItemIndex],
                          end: value?.value,
                        };
                        setFieldValue(props?.name, _values);
                      }}
                    />
                    {isEditMode && (
                      <IconButton
                        size="md20"
                        name="trash"
                        colorSchema="transparent"
                        type="far"
                        onClick={() => {
                          setFieldValue(
                            props?.name,
                            valuesForASingleDay?.filter(
                              (_, ind: number) => ind !== index
                            )
                          );
                        }}
                      />
                    )}
                  </Flex>
                ))}
                {isMaxLimitReached && (
                  <Flex className={classes.limitBox}>
                    <Typography size={15} font="700" height={21} color="error">
                      {t('reach_max_error')}
                    </Typography>
                  </Flex>
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      );
    },
    label: '',
  }));
  const groups = isEditMode ? [name, duration, ..._groups] : _groups;

  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      onSuccess={handleSuccess}
      transform={transform}
      apiFunc={handleSubmit}
    >
      {({ resetForm }) => (
        <Flex>
          <DynamicFormBuilder groups={groups} />
          <Flex
            className={cnj(
              classes.formButtonsContainer,
              classNames?.buttonsContainer
            )}
            key={String(isEditMode) + String(isNew)}
          >
            {isEditMode ? (
              isNew ? (
                <>
                  <Button
                    label={t('cancel')}
                    className={classes.formButton}
                    schema="ghost"
                    onClick={removeNewTimesheet}
                  />
                  <SubmitButton
                    active
                    label={t('done')}
                    className={classes.formButton}
                  />
                </>
              ) : (
                <>
                  <Button
                    label={t('cancel')}
                    className={classes.formButton}
                    schema="ghost"
                    onClick={handleCancel(resetForm)}
                  />
                  <SubmitButton
                    active
                    label={t('save')}
                    className={classes.formButton}
                  />
                </>
              )
            ) : (
              <>
                <Button
                  label={t('remove')}
                  schema="error-semi-transparent"
                  leftIcon="trash"
                  leftType="far"
                  className={classes.formButton}
                  onClick={removeAvailability}
                />
                <Button
                  label={t('edit')}
                  schema="gray"
                  leftIcon="pen"
                  leftType="far"
                  className={classes.formButton}
                  onClick={() => setIsEditMode(true)}
                />
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Form>
  );
};

export default EventForm;
