import classes from './EmptySectionInModules.module.scss';

import React from 'react';
import Button from '@lobox/uikit/Button';
import cnj from '@lobox/uikit/utils/cnj';
import Flex from '@lobox/uikit/Flex';
import Typography from '@lobox/uikit/Typography';
import EmptySearchSvg from '@shared/svg/EmptySearchSvg';

export type EmptyProps = {
  buttonProps?: {
    title?: string;
    onClick?: (x: any) => any;
    to?: string;
  };
  title?: string;
  text?: string;
  classNames?: {
    container?: string;
  };
  doNotShowSectionIfEmpty?: boolean;
  isEntirePage?: boolean;
};

const EmptySectionInModules: React.FC<EmptyProps> = ({
  title,
  text,
  buttonProps,
  classNames,
  isEntirePage,
}) => {
  if (!title) return null;

  return (
    <Flex
      className={cnj(
        classes.container,
        isEntirePage && classes.isEntirePage,
        classNames?.container
      )}
    >
      <EmptySearchSvg />
      {title && (
        <Typography
          className={classes.title}
          color="smoke_coal"
          size={20}
          height={23.5}
          font="700"
          textAlign="center"
        >
          {title}
        </Typography>
      )}
      {text && (
        <Typography
          textAlign="center"
          className={classes.text}
          color="gray"
          size={15}
          height={21}
          font="400"
        >
          {text}
        </Typography>
      )}
      {buttonProps && (
        <Button
          label={buttonProps?.title}
          onClick={buttonProps?.onClick}
          className={classes.button}
        />
      )}
    </Flex>
  );
};

export default EmptySectionInModules;
